



























































































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Establishment, Prognosis, Shift } from '@/common';
import { DailyBalanceFacade } from '@/service/dailyBalance';
import { DailyBalanceDto } from '@/types/dailyBalance';
import { WageCosts } from '@/api/types';

interface PrognosisObject {
  progTurnover: string;
  realTurnover: string;
  progWageCost: string;
  realWageCost: string;
  progWageCostPerc: string;
  realWageCostPerc: string;
  guestCount: {
    dag: string;
    avond: string;
  };
}

export default defineComponent({
  props: {
    establishment: {
      required: true,
      type: Object as PropType<Establishment>,
    },
    prognosis: {
      required: true,
      type: Array as PropType<Prognosis[]>,
    },
    shifts: {
      required: true,
      type: Array as PropType<Shift[]>,
    },
    wageCosts: {
      required: true,
      type: Object as PropType<WageCosts>,
    },
    dailyBalance: {
      type: Object as PropType<DailyBalanceDto>,
    },
  },
  setup(props) {
    const loading = ref(false);
    const error = ref('');
    const prognosisObject = ref<PrognosisObject>({
      progTurnover: 'nb',
      realTurnover: 'nb',
      progWageCost: 'nb',
      realWageCost: 'nb',
      progWageCostPerc: 'nb',
      realWageCostPerc: 'nb',
      guestCount: {
        dag: 'nb',
        avond: 'nb',
      },
    });

    const shiftHours = computed(() =>
      props.shifts.reduce((total, shift) => total + shift.costs, 0),
    );

    const getWagePerc = (wagecost: number, turnover: number) => {
      const perc = (wagecost / turnover) * 100;
      if (!isFinite(perc)) {
        return '-';
      } else {
        return perc.toFixed();
      }
    };

    const getPrognosisGuestByType = (type: string) => {
      const result = props.prognosis.find(prog =>
        prog.type?.name.toLowerCase().startsWith(type.toLowerCase()),
      );
      return result?.guests ?? 0;
    };

    const getGuests = (dayPart: string, guests: number) => {
      if (dayPart.toLowerCase() === 'dag') {
        if (guests < 0) return '0';
        if (guests < 40) return '0-40';
        if (guests < 70) return '40-70';
        if (guests < 100) return '70-100';
        if (guests < 140) return '100-140';
        if (guests < 180) return '140-180';
        if (guests < 220) return '180-220';
        if (guests < 250) return '220-250';
        if (guests < 300) return '250-300';
        if (guests < 350) return '300-350';
        else return '350+';
      } else {
        if (guests < 0) return '0';
        if (guests < 40) return '0-40';
        if (guests < 70) return '40-70';
        if (guests < 100) return '70-100';
        if (guests < 120) return '100-120';
        if (guests < 150) return '120-150';
        if (guests < 180) return '150-180';
        if (guests < 220) return '180-220';
        if (guests < 260) return '220-260';
        if (guests < 300) return '260-300';
        else return '300+';
      }
    };

    watch(
      [() => props.prognosis, () => props.dailyBalance, () => props.wageCosts],
      () => {
        const prog = props.prognosis.find(p => p.type?.name === 'Totaal');
        let realTurnover = 0;
        if (props.dailyBalance) {
          const balance = new DailyBalanceFacade(props.dailyBalance);
          realTurnover = balance.getTurnoverAmountExclVat() ?? 0;
        }

        prognosisObject.value = {
          progTurnover: prog?.turnover ? '€' + prog?.turnover?.toFixed(0) : 'nb',
          realTurnover: '€' + realTurnover.toFixed(0) ?? '-',
          progWageCost: props.wageCosts.plannedCosts.toFixed(0) ?? '-',
          realWageCost: props.wageCosts.actualCosts?.toFixed(0) ?? '-',
          progWageCostPerc: getWagePerc(props.wageCosts.plannedCosts ?? 0, prog?.turnover ?? 0),
          realWageCostPerc: getWagePerc(props.wageCosts.actualCosts ?? 0, realTurnover),
          guestCount: {
            dag: getGuests('dag', getPrognosisGuestByType('Lunch')),
            avond: getGuests('avond', getPrognosisGuestByType('Diner')),
          },
        };
      },
      { immediate: true },
    );

    return {
      loading,
      error,
      prognosisObject,
      shiftHours,
      getWagePerc,
      getPrognosisGuestByType,
      getGuests,
    };
  },
});
