import { routes as home } from './home';
import { routes as dashboard } from './dashboard';
import { routes as journal } from './journal';
import { routes as journals } from './journals';
import { routes as settings } from './settings';
import { routes as insights } from './insights';
import { RouteConfig } from 'vue-router';

export default [
  ...home,
  ...journal,
  ...journals,
  ...settings,
  ...dashboard,
  ...insights,
] as RouteConfig[];
