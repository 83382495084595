

















































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useGetters } from '@u3u/vue-hooks';
import { addWeeks, format, startOfWeek } from 'date-fns';
import { Journal, ShiftReview } from '@/common/types';

import { findBetween } from '@/api/journals';

import WeekOverview from './components/weekOverview/weekOverview.vue';
import HeaderWidget from './components/header/HeaderWidget.vue';

import EmployeesOverview from './components/employees/EmployeeOverview.vue';
import IncidentsOverview from './components/incidents/IncidentsOverview.vue';
import NotesOverview from './components/notes/NotesOverview.vue';
import ReceiptList from '@/components/receipts/ReceiptList.vue';

import JournalsOverview from './components/journals/JournalsOverview.vue';

import { listBetween } from '@/api/dailyBalance';
import { DailyBalanceDto } from '@/types/dailyBalance';

export default defineComponent({
  components: {
    HeaderWidget,
    WeekOverview,
    EmployeesOverview,
    ReceiptList,
    IncidentsOverview,
    NotesOverview,
    JournalsOverview,
  },
  props: {
    establishmentId: {
      required: true,
      type: Number,
    },
    date: {
      required: true,
      type: Date,
    },
  },
  setup(props) {
    const loading = ref(false);
    const error = ref('');
    const dailyBalanceData = ref<DailyBalanceDto[]>([]);
    const journals = ref<Journal[]>([]);

    const { byId: getEstablishmentById } = useGetters('establishments', ['byId']);
    const establishment = computed(() => getEstablishmentById.value(props.establishmentId));

    const dateAndEstablishment = computed(
      () => `${props.establishmentId}:${format(props.date, 'yyyy-MM-dd')}`,
    );

    const shiftReviews = computed(() => {
      return journals.value.reduce((result, journal) => {
        return result.concat(journal.shiftReviews ?? []);
      }, [] as ShiftReview[]);
    });

    const dateStartOfWeek = computed(() => startOfWeek(props.date, { weekStartsOn: 1 }));
    const dateStartOfNextWeek = computed(() =>
      startOfWeek(addWeeks(dateStartOfWeek.value, 1), { weekStartsOn: 1 }),
    );

    watch(
      dateAndEstablishment,
      async () => {
        const from = format(startOfWeek(props.date, { weekStartsOn: 1 }), 'yyyy-MM-dd');
        const to = format(startOfWeek(addWeeks(props.date, 1), { weekStartsOn: 1 }), 'yyyy-MM-dd');

        try {
          loading.value = true;
          journals.value = await findBetween(props.establishmentId, from, to);

          dailyBalanceData.value = await listBetween({
            establishmentId: props.establishmentId,
            from: format(dateStartOfWeek.value, 'yyyy-MM-dd'),
            to: format(dateStartOfNextWeek.value, 'yyyy-MM-dd'),
          });
          dailyBalanceData.value.sort((a, b) => (a.dob > b.dob ? 1 : -1));
        } catch (e) {
          if (typeof e === 'string') {
            error.value = e;
          } else {
            error.value = 'er is iets misgegaan';
          }
        } finally {
          loading.value = false;
        }
      },
      { immediate: true },
    );

    return {
      loading,
      error,
      dailyBalanceData,
      journals,
      establishment,
      dateAndEstablishment,
      shiftReviews,
      dateStartOfWeek,
      dateStartOfNextWeek,
    };
  },
});
