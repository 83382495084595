import { Ref, watch, computed } from '@vue/composition-api';
import { useGetters, useMutations, useRouter } from '@u3u/vue-hooks';
import { useAsync } from '@/composables';
import { MutationTypes } from '@/store/modules/establishments/mutation-types';
import { findAll } from '@/api/establishments';
import { Establishment } from '@/common';

const module = 'establishments';

export function useEstablishments() {
  const { route } = useRouter();
  const { sortedEstablishments, byId } = useGetters(module, ['sortedEstablishments', 'byId']);

  const establishments = sortedEstablishments as Ref<Establishment[]>;
  const establishment = computed<Establishment | undefined>(() => {
    const id = route.value.params?.establishmentId;
    return id ? byId.value(Number(id)) : undefined;
  });

  const { SET_ESTABLISHMENTS } = useMutations(module, [MutationTypes.SET_ESTABLISHMENTS]);

  const { data, isLoading, error, retry } = useAsync(findAll);

  if (!sortedEstablishments.value.length) {
    retry();
  }

  watch(data, data => SET_ESTABLISHMENTS(data ?? []));

  return {
    establishments,
    establishment,
    isLoading,
    error,
    reload: retry,
  };
}
