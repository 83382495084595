


































































































































































import { defineComponent, onMounted, reactive, ref, watch, computed } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { useAsync } from '@/composables';
import client from '@/api/client';
import { Journal } from '@/common';

interface StartupForm {
  name: string;
  startUpKitchenRating: number;
  startUpKitchenComment: string | null;
  startUpServiceRating: number;
  startUpServiceComment: string | null;
  startUpRating: number;
  startUpComment: string | null;
}

const API_URL = process.env.VUE_APP_JOURNAL_API_URL;

export default defineComponent({
  props: {
    journalId: {
      required: true,
      type: Number,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  setup(props) {
    const startUpData = ref<StartupForm | null>(null);
    const form = reactive<StartupForm>({
      name: '',
      startUpKitchenRating: -1,
      startUpKitchenComment: '',
      startUpServiceRating: -1,
      startUpServiceComment: '',
      startUpRating: -1,
      startUpComment: '',
    });

    onMounted(async () => {
      const response = await client.get<Journal>(`${API_URL}/journals/${props.journalId}`);

      form.name = response.data.name;
      form.startUpKitchenRating = response.data.startUpKitchenRating ?? -1;
      form.startUpKitchenComment = response.data.startUpKitchenComment ?? '';
      form.startUpServiceRating = response.data.startUpServiceRating ?? -1;
      form.startUpServiceComment = response.data.startUpServiceComment ?? '';
      form.startUpRating = response.data.startUpRating ?? -1;
      form.startUpComment = response.data.startUpComment ?? '';
    });

    async function save(data: StartupForm | null) {
      if (!data) return;

      const response = await client.put<Journal>(`${API_URL}/journals/${props.journalId}`, data);
      startUpData.value = null;
      return response.data;
    }

    const { isLoading, error, data } = useAsync(save, startUpData);

    watch(data, () => {
      if (data.value?.startUpKitchenRating) {
        form.startUpKitchenRating = data.value.startUpKitchenRating;
      }
      if (data.value?.startUpKitchenComment) {
        form.startUpKitchenComment = data.value.startUpKitchenComment;
      }

      if (data.value?.startUpServiceRating) {
        form.startUpServiceRating = data.value.startUpServiceRating;
      }
      if (data.value?.startUpServiceComment) {
        form.startUpServiceComment = data.value.startUpServiceComment;
      }

      if (data.value?.startUpRating) {
        form.startUpRating = data.value.startUpRating;
      }
      if (data.value?.startUpComment) {
        form.startUpComment = data.value.startUpComment;
      }
    });

    function submit() {
      startUpData.value = form;
    }

    return {
      isLoading,
      error,
      showError: computed(() => !!error.value),
      submit,
      form,
    };
  },
});
