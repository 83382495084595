import { format as _format, formatDistanceToNow, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';

function normalizeDate(date: Date | string) {
  return date instanceof Date ? date : parseISO(date);
}

function mergeOptions(options?: object) {
  return {
    locale: nl,
    ...options,
  };
}

export function parseUTC(val: string) {
  return utcToZonedTime(val, 'UTC');
}

export function format(date: Date | string, format: string, options?: object) {
  return _format(normalizeDate(date), format, mergeOptions(options));
}

export function formatDateISO(date: Date | string) {
  return format(date, 'yyyy-MM-dd');
}

export function formatTime(date: Date | string) {
  return format(date, 'HH:mm');
}

export function distanceToNow(date: Date | string, options?: object) {
  return formatDistanceToNow(normalizeDate(date), mergeOptions(options));
}
