import client from '../client';
import useAxios from '@/util/axios';
import { PrognosisDto } from './types';
import { normalize } from './serialize';

const { getCancelToken } = useAxios();

export async function GetPrognosisByDate(
  establishmentId: number,
  date: string,
  signal?: AbortSignal,
) {
  const params = { date };
  const response = await client.get<PrognosisDto[]>(
    `/establishments/${establishmentId}/prognosis/`,
    {
      params,
      cancelToken: getCancelToken(signal),
    },
  );
  return response.data.map(normalize);
}

export async function GetPrognosisByDateRange(
  establishmentId: number,
  start: string,
  end: string,
  signal?: AbortSignal,
) {
  const params = { start, end };
  const response = await client.get<PrognosisDto[]>(
    `/establishments/${establishmentId}/prognosis/`,
    {
      params,
      cancelToken: getCancelToken(signal),
    },
  );
  return response.data.map(normalize);
}
