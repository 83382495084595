export const DailyBalancePropertyTypes = {
  TURNOVER: 1,
  TURNOVER_EXCL_VAT: 2,
  CR_CASH: 3,
  CR_PIN: 4,
  CR_IZETTLE: 5,
  CASH: 6,
  PIN: 7,
  IZETTLE: 8,
  VOIDED: 9,
  VOUCHERS: 10,
  DEBTOR: 11,
  EMPLOYEE_PAYMENTS: 12,
  SPECIAL_PAYMENTS: 13,
  OFFERED: 14,
  REPRESENTATION: 15,
  TOTAL_REGISTER_AMOUNT: 16,
  DIFFERENCE_AMOUNT: 17,
} as const;

export type DailyBalancePropertyType = typeof DailyBalancePropertyTypes[keyof typeof DailyBalancePropertyTypes];

export type DailyBalanceDto = {
  establishmentId: number;
  dob: string;
  properties: DailyBalancePropertyDto[];
  userData: DailyBalanceUserData;
  miscData: DailyBalanceMiscData;
};

export type DailyBalancePropertyDto = {
  type: DailyBalancePropertyType;
  amount: number;
};

export type DailyBalanceUserData = {
  coins: Record<string, number | string>;
  pins: string[];
  payments: DailyBalanceUserDataPayment[];
  diff: string;
};

export type DailyBalanceMiscData = {
  count: DailyBalanceMiscDataCount[];
  coins: DailyBalanceMiscDataCoin[];
};

export type DailyBalanceUserDataPayment = {
  name: string;
  amount: string;
};

export type DailyBalanceMiscDataCount = {
  name: string;
  count: number;
  number: number;
};

export type DailyBalanceMiscDataCoin = {
  coinName: string;
  roleAmount: number;
  count: string;
};
