import {
  DailyBalanceDto,
  DailyBalanceMiscData,
  DailyBalancePropertyDto,
  DailyBalancePropertyType,
  DailyBalancePropertyTypes,
  DailyBalanceUserData,
  DailyBalanceUserDataPayment,
} from '@/types/dailyBalance';

export class DailyBalanceFacade {
  public establishmentId: number;
  public dob: string;
  public properties: Map<DailyBalancePropertyType, DailyBalancePropertyDto> = new Map();
  public userData: DailyBalanceUserData;
  public miscData: DailyBalanceMiscData;

  constructor(data: DailyBalanceDto) {
    this.establishmentId = data.establishmentId;
    this.dob = data.dob;
    data.properties.forEach(p => {
      this.properties.set(p.type, p);
    });
    this.userData = data.userData;
    this.miscData = data.miscData;
  }

  getTurnoverAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.TURNOVER)?.amount;
  }

  getTurnoverWithVouchersAmount(): number | undefined {
    const turnover = this.getTurnoverAmount();
    const voucherAmount = this.getVoucherAmount() ?? 0;
    if (turnover !== undefined) {
      return turnover + voucherAmount;
    }
    return undefined;
  }

  getTurnoverAmountExclVat(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.TURNOVER_EXCL_VAT)?.amount;
  }

  getCashAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.CR_CASH)?.amount;
  }

  getCoinsAmount(): number {
    const coins = this.getCoins();
    let result = 0;
    coins?.forEach((amount, coin) => (result += coin * amount));
    return result;
  }

  getCoinsDifferenceAmount(): number {
    return this.getCoinsAmount() - (this.getCashAmount() ?? 0);
  }

  getPinAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.CR_PIN)?.amount;
  }

  getUserPinAmount(): number {
    const pins = this.getPins() ?? [];
    return pins.reduce((agg, amount) => agg + amount, 0);
  }

  getPinDifferenceAmount(): number {
    return this.getUserPinAmount() - (this.getPinAmount() ?? 0);
  }

  getIZettleAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.IZETTLE)?.amount;
  }

  getPaymentsAmount(): number {
    const payments = this.getPayments() ?? [];
    return payments.reduce((agg, payment) => agg + +payment.amount, 0);
  }

  getSpecialPaymentsAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.SPECIAL_PAYMENTS)?.amount;
  }

  getDebtorAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.DEBTOR)?.amount;
  }

  getVoidAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.VOIDED)?.amount;
  }

  getVoucherAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.VOUCHERS)?.amount;
  }

  getOfferedAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.OFFERED)?.amount;
  }

  getRepresentationAmount(): number | undefined {
    return this.properties.get(DailyBalancePropertyTypes.REPRESENTATION)?.amount;
  }

  getInCashRegisterAmount(): number {
    return (
      this.getCoinsAmount() +
      this.getUserPinAmount() +
      (this.getIZettleAmount() ?? 0) +
      this.getPaymentsAmount() +
      (this.getSpecialPaymentsAmount() ?? 0) +
      (this.getDebtorAmount() ?? 0)
    );
  }

  getDifferenceAmount(): number {
    return (this.getTurnoverWithVouchersAmount() ?? 0) - (this.getInCashRegisterAmount() ?? 0);
  }

  getStoredDiffAmount(): number {
    return parseInt(this.userData?.diff as string);
  }

  getCoins(): Map<number, number> {
    const data = this.userData?.coins as Record<number, number | string>;
    const result = new Map<number, number>();
    Object.entries(data).forEach(([key, value]) => result.set(Number(key), Number(value)));
    return result;
  }

  getPins(): number[] {
    const data = (this.userData?.pins as string[]) ?? [];
    return data?.map(Number);
  }

  getPayments(): DailyBalanceUserDataPayment[] {
    return this.userData?.payments ?? [];
  }
}
