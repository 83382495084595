


































































































import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useRouter } from '@u3u/vue-hooks';
import { endOfWeek, format, getWeek, startOfWeek } from 'date-fns';
import { nl } from 'date-fns/locale';
import { findBetween } from '@/api/journals';
import { findByJournal as findNotesByJournal } from '@/api/notes';
import { findByJournal as findIncidentsByJournal } from '@/api/incidents';
import { findByJournal as findShiftReviewsByJournal } from '@/api/shiftReviews';
import * as shiftApi from '@/api/shifts';
import { IncidentReport, Journal, JournalNote, Shift, ShiftReview } from '@/common';

interface JournalsTableRow {
  date: string;
  day: string;
  daypart: string;
  rating: {
    icon: string;
    variant: string;
  };
  labour: number;
  incidents: number;
  notes: {
    danger: number;
    warning: number;
    success: number;
  };
  employees: {
    danger: number;
    success: number;
  };
}

export default defineComponent({
  setup() {
    const { route, router } = useRouter();
    const loading = ref(false);
    const errorMessage = ref('');
    const date = ref(new Date());

    function formatDate(date: Date) {
      return format(date, 'yyyy-MM-dd');
    }

    const today = ref(formatDate(date.value));
    const week = ref(getWeek(new Date(today.value)));
    const startWeek = ref(formatDate(startOfWeek(date.value, { weekStartsOn: 1 })));
    const endWeek = ref(formatDate(endOfWeek(date.value, { weekStartsOn: 1 })));

    const journals = ref<Journal[]>([]);
    const shiftReviews = ref<Record<number, ShiftReview[]>>({});
    const shifts = ref<Record<number, Shift[]>>({});
    const notes = ref<Record<number, JournalNote[]>>({});
    const incidents = ref<Record<number, IncidentReport[]>>({});
    const establishmentId = ref(Number(route.value.params?.establishmentId));

    watch(
      establishmentId,
      establishmentId => {
        if (!establishmentId) {
          router.push({ name: 'LandingsPage' });
        }
      },
      { immediate: true },
    );

    const fields = ref([
      {
        key: 'rating',
        label: '',
        sortable: false,
      },
      {
        key: 'date',
        label: 'Datum',
        sortable: true,
      },
      {
        key: 'day',
        label: 'Dag',
        sortable: true,
      },
      {
        key: 'daypart',
        label: 'Dagdeel',
        sortable: true,
      },
      {
        key: 'employees',
        label: 'Medewerkers',
        sortable: false,
      },
      {
        key: 'notes',
        label: 'Opmerkingen',
        sortable: false,
      },
      {
        key: 'incidents',
        label: 'Acties',
        sortable: false,
      },
      {
        key: 'open',
        label: '',
        sortalbe: false,
      },
    ]);

    function getDay(dob: string) {
      return format(new Date(dob), 'EEEE', { locale: nl });
    }

    function incidentsCount(incidents: IncidentReport[]) {
      let incidentCount = 0;
      if (incidents != undefined) {
        incidentCount = incidents.length;
      }
      return incidentCount;
    }

    function getShiftReviewsOverview(reviews: ShiftReview[]) {
      const overview = {
        danger: 0,
        success: 0,
      };
      reviews.forEach(review => {
        if (review.rating === 0) {
          overview.danger = overview.danger + 1;
        }
        if (review.rating === 2) {
          overview.success = overview.success + 1;
        }
      });
      return overview;
    }

    function getNotesOverview(notes: JournalNote[]) {
      const overview = {
        danger: 0,
        warning: 0,
        success: 0,
      };
      notes.forEach(note => {
        if (note.rating === 0) {
          overview.danger = overview.danger + 1;
        }
        if (note.rating === 1) {
          overview.warning = overview.warning + 1;
        }
        if (note.rating === 2) {
          overview.success = overview.success + 1;
        }
      });
      return overview;
    }

    function getRating(rating: number | undefined | null) {
      const result = {
        icon: '',
        variant: '',
      };

      if (rating === 0) {
        result.icon = 'emoji-frown';
        result.variant = 'danger';
      }
      if (rating === 1 || rating === null || rating === undefined) {
        result.icon = 'emoji-smile';
        result.variant = 'warning';
      }
      if (rating === 2) {
        result.icon = 'emoji-laughing';
        result.variant = 'success';
      }
      return result;
    }

    const chart = computed(() => {
      const chart: JournalsTableRow[] = [];
      journals.value.forEach(journal => {
        const tableRow: JournalsTableRow = {
          date: formatDate(new Date(journal.dob)),
          day: getDay(journal.dob),
          daypart: journal.name,
          rating: getRating(journal.rating),
          incidents: incidentsCount(incidents.value[journal.id]),
          notes: getNotesOverview(notes.value[journal.id]),
          employees: getShiftReviewsOverview(shiftReviews.value[journal.id]),
          labour: 30,
        };
        chart.push(tableRow);
      });
      return chart;
    });

    onMounted(async () => {
      try {
        loading.value = true;
        journals.value = await findBetween(establishmentId.value, startWeek.value, endWeek.value);
        shiftReviews.value = {};
        shifts.value = {};
        notes.value = {};
        for (const journal of journals.value) {
          const id = journal.id;
          shiftReviews.value[id] = await findShiftReviewsByJournal(id);
          shifts.value[id] = await shiftApi.search({
            establishmentId: establishmentId.value,
            date: journal.dob,
          });
          notes.value[id] = await findNotesByJournal(id);
          incidents.value[id] = await findIncidentsByJournal(id);
        }
      } catch (e) {
        errorMessage.value = e;
      } finally {
        loading.value = false;
      }
    });

    return {
      loading,
      errorMessage,
      date,
      today,
      week,
      startWeek,
      endWeek,
      journals,
      shiftReviews,
      shifts,
      notes,
      incidents,
      establishmentId,
      fields,
      chart,
    };
  },
});
