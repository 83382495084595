export type AlohaEstablishment = 'WALRUS_SN' | 'WALRUS_LWD';

export enum WindUnit {
  KMH = 'KMH',
  MS = 'MS',
  BFT = 'BFT',
}

export interface Establishment {
  id: number;
  name: string;
  nostradamusId?: string;
  weatherCityId?: number;
  weatherWindUnit?: WindUnit;
  weatherWindThreshold?: number;
  maxWindspeed?: number;
  reservationsBookId?: number;
  changeBin?: number;
  count?: Count[];
  departments?: Department[];
  teams?: Team[];
  dayparts?: Daypart[];
  inventoryItems?: InventoryItem[];
  occupanceRanges?: OccupanceRange[];
  occupationPlanning?: OccupationEmployeePlanning[];
  occupanceDepartments?: OccupanceDepartment[];
}

export interface Daypart {
  id?: number;
  establishmentId: number;
  name: string;
  start: string;
  end: string;
  establishment?: Establishment;
}

export interface InventoryItem {
  id: number;
  establishmentId: number;
  name: string;
  expectedNumber?: number;
  establishment?: Establishment;
}

export interface OccupanceRange {
  id?: number;
  establishmentId: number;
  daypartId: number;
  name: string;
  lower: number;
  upper: number;
  establishment?: Establishment;
  daypart?: Daypart;
}

export interface OccupanceDepartment {
  id?: number;
  establishmentId: number;
  name: string;
  teams: Team[];
}

export interface OccupationEmployeePlanning {
  id?: number;
  establishmentId: number;
  departmentId: number;
  occupanceRangeId: number;
  sun: number;
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
  establishment?: Establishment;
  department?: Department;
  occupanceRange?: OccupanceRange;
}

interface Count {
  id?: number;
  name: string;
  value: number;
}

export interface Department {
  id?: number;
  establishmentId: number;
  name: string;
  establishment?: Establishment;
  teams?: Team[];
}

export interface Team {
  id: number;
  establishmentId: number;
  departmentId: number;
  name: string;
  establishment?: Establishment;
  department?: Department[];
}

export interface Employee {
  id: number;
  establishmentId: number;
  departmentId?: number;
  externalId: string;
  name: string;
  dateOfBirth?: string;
  gender: string;
  start: string;
  end?: string;
  function?: number;
  hourlySalary: number;
  salaryRate: number;
  clockedIn?: {
    enterTime: string;
    exitTime?: string;
  };
  establishment?: Establishment;
  department?: Department;
}

export interface Shift {
  id: number;
  establishmentId?: number;
  employeeId?: number;
  departmentId?: number;
  teamId?: number;
  workspace?: string;
  externalId?: string;
  date: Date;
  start: string;
  end: string;
  hours: number;
  costs: number;
  establishment?: Establishment;
  employee?: Employee;
  department?: Department;
  team?: Team;
  dayparts?: Partial<Daypart[]>;
}

export interface Journal {
  id: number;
  establishmentId: number;
  dob: string;
  daypart?: Daypart;
  name: string;
  rating?: number;
  closedAt?: string;
  startUpKitchenComment?: string;
  startUpKitchenRating?: number;
  startUpServiceComment?: string;
  startUpServiceRating?: number;
  startUpComment?: string;
  startUpRating?: number;
  closeStaffComment?: string;
  closeStaffRating?: number;
  closeKitchenComment?: string;
  closeKitchenRating?: number;
  closeGuestsComment?: string;
  closeGuestsRating?: number;
  closeMiscComment?: string;
  closeMiscRating?: number;
  establishment?: Establishment;
  shiftReviews?: ShiftReview[];
  journalNotes?: JournalNote[];
  incidentReports?: IncidentReport[];
}

export interface PlanningPrognosisRange {
  department: Department;
  daypart: Daypart;
  occupanceRange?: string;
  limit: number | undefined;
}

export interface Prognosis {
  establishmentId: number;
  typeId: number;
  date: Date;
  guests: number;
  spending: number;
  turnover: number;
  establishment?: Establishment;
  type?: PrognosisType;
}

export interface PrognosisType {
  id: number;
  establishmentId: number;
  parentId: number;
  name: string;
  establishment?: Establishment;
  parent?: PrognosisType;
}

export interface ShiftReview {
  id: number;
  journalId: number;
  shiftId: number;
  rating: number;
  comment?: string;
  sick: boolean;
  sicknessComment?: string;
  journal?: Journal;
  shift?: Shift;
  tags?: Tag[];
  floorplanTags?: Tag[];
}

export type TagType = 'INCIDENT_REPORT' | 'SHIFT_REVIEW' | 'FLOORPLAN';

export interface Tag {
  id: number;
  establishmentId: number;
  type: TagType;
  name: string;
}

export interface Incident {
  id: number;
  establishmentId: number;
  name: string;
  establishment?: Establishment;
  incidentReports?: IncidentReport[];
}

export interface IncidentReport {
  id: number;
  journalId: number;
  incidentId: number;
  employeeId?: number;
  description?: string;
  action?: string;
  journal?: Journal;
  incident?: Incident;
  employee?: Employee;
  tags?: Tag[];
}

export interface IncidentReportDto {
  description?: string;
  action?: string;
  incidentId?: number;
  employeeId?: number;
  tags?: number[];
}

export interface JournalNote {
  id: number;
  journalId: number;
  subject: string;
  comment: string;
  rating: number;
  journal?: Journal;
}

export interface ReservationBook {
  id: number;
  name: string;
}

export interface Reservations {
  type: string;
  dayPart: Daypart;
  amount: number;
  range?: OccupanceRange;
  list?: Reservation[];
}

export interface Reservation {
  type: ReservationType;
  establishment: string;
  reservationType: string;
  amountOfPeople: number;
  date: string;
  timeFrom: string;
  timeTo: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string;
  email: string;
  phoneNumber: string;
  remarks: string | null;
  companyName: string | null;
  companyInvoice: string | null;
  costCenter: string | null;
  department: string | null;
  tableNumber: string | null;
  employee: string;
}

export enum ReservationType {
  DINNER = 'DINNER',
  LUNCH = 'LUNCH',
  HIGH_WINE_BEER = 'HIGH_WINE_BEER',
  HIGH_TEA = 'HIGH_TEA',
  DRINKS = 'DRINKS',
  OTHER = 'OTHER',
}

export type ReceiptDto = {
  id: number;
  establishmentId: number;
  date: string;
  check: string;
  tableNo: string;
  openedAt: string;
  closedAt: string;
  firstOrderAt: string;
  openEmployeeFirstname?: string;
  openEmployeeMiddlename?: string;
  openEmployeeLastname?: string;
  openEmployeeNickname?: string;
  closeEmployeeFirstname?: string;
  closeEmployeeMiddlename?: string;
  closeEmployeeLastname?: string;
  closeEmployeeNickname?: string;
  guests: number;
  salesAmount: number;
  taxAmount: number;
  accountIdent?: string;
  accountNumber?: string;
  accountFirstname?: string;
  accountMiddlename?: string;
  accountLastname?: string;
  accountAddress1?: string;
  accountAddress2?: string;
  accountAddress3?: string;
  accountAddress4?: string;
  accountCity?: string;
  accountState?: string;
  accountZipCode?: string;
  accountPhone?: string;
  createdAt: string;
  lastModifiedAt: string;

  items: ReceiptItemDto[];
  compensations: ReceiptCompensationDto[];
  salesByTax: ReceiptSalesByTaxDto[];
  invoice?: ReceiptInvoiceDto;
  contactDetails?: ReceiptContactDetailsDto;
};

type ReceiptItemType = 'ITEM' | 'PROMOTION';

export type ReceiptItemDto = {
  itemId: number;
  receiptId: number;
  name: string;
  price: number;
  discPrice: number;
  inclTax: number;
  quantity: number;
  type: ReceiptItemType;
};

type ReceiptCompensationType = 'AMOUNT' | 'RATE';

export type ReceiptCompensationDto = {
  compensationId: number;
  receiptId: number;
  name: string;
  amount: number;
  type: ReceiptCompensationType;
};

export type ReceiptSalesByTaxDto = {
  receiptId: number;
  rate: number;
  salesAmount: number;
  taxAmount: number;
};

export type ReceiptInvoiceDto = {
  establishmentId: number;
  check: string;
  date: string;
  invoiceNo?: string;
};

export type ReceiptContactDetailsDto = {
  receiptId: number;
  createdDate: string;
  reference?: string;
  contactPerson?: string;
  costCenter?: string;
  department?: string;
  debtorName?: string;
  debtorAddress1?: string;
  debtorAddress2?: string;
  debtorHousenumber?: string;
  debtorPostalCode?: string;
  debtorCity?: string;
  debtorCountry?: string;
  debtorPhone?: string;
  debtorEmail?: string;
};

export type UpdateReceiptContactDetailsDto = Omit<
  ReceiptContactDetailsDto,
  'receiptId' | 'createdDate'
>;
