

























































import Account from '@/components/Account.vue';
import { useRouter } from '@u3u/vue-hooks';
import { computed, defineComponent } from '@vue/composition-api';
import { useEstablishments } from './util/establishments';

const APP_TITLE = process.env.VUE_APP_TITLE;
const CONTACT_LIST_URL = process.env.VUE_APP_CONTACT_LIST_URL;
const PASSWORD_LIST_URL = process.env.VUE_APP_PASSWORD_LIST_URL;
const HOLY_GRAIL_URL = process.env.VUE_APP_HOLY_GRAIL_URL;

export default defineComponent({
  name: 'App',
  components: { Account },
  setup() {
    const { route, router } = useRouter();
    const { establishments, establishment } = useEstablishments();

    const establishmentId = computed({
      get: () => Number(route.value.params?.establishmentId),
      set: val =>
        router.push({
          name: route.value.name ?? 'dashboard',
          params: { establishmentId: String(val) },
          query: route.value.query,
        }),
    });

    function changeEstablishment(id: number) {
      if (establishmentId.value !== id) {
        establishmentId.value = id;
      }
    }

    return {
      title: APP_TITLE,
      contactListUrl: CONTACT_LIST_URL,
      passwordListUrl: PASSWORD_LIST_URL,
      holyGrailUrl: HOLY_GRAIL_URL,
      establishments,
      establishment,
      establishmentId,
      changeEstablishment,
    };
  },
});
