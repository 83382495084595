












































































































































































import { computed, defineComponent, onMounted, PropType, ref } from '@vue/composition-api';
import { Establishment, Prognosis, Reservation, ReservationType, Shift } from '@/common';
import { GetPrognosisByDateRange } from '@/api/prognosis';
import { getCurrentWeather, getHistoricalWeather } from '@/api/weather';
import { CachedWeatherData, CurrentForecastWeatherData } from '@/api/weather/types';
import { DailyBalanceDto } from '@/types/dailyBalance';
import * as reservationsService from '@/api/reservations';
import { search as searchShifts } from '@/api/shifts';
import { addDays, addWeeks, format, isSameDay, startOfWeek } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import PrognosisComponent from './components/PrognosisComponent.vue';
import ReservationComponent from './components/ReservationComponent.vue';
import WeatherComponent from './components/WeatherComponent.vue';
import { WageCosts } from '@/api/types';
import { searchWageCosts } from '@/api';

interface DayOverviewObject {
  date: Date;
  dayName: string;
  weather?: WeatherData;
  reservations?: ReservationData;
  prognosis?: Prognosis[];
  dailyBalance?: DailyBalanceDto;
  shifts?: Shift[];
  wageCosts?: WageCosts;
}

interface WeatherData {
  tempDay: string;
  tempEve: string;
  weatherIcon: string;
  wind: number;
  windIcon: number;
  precipitation: string;
}

interface ReservationData {
  dinner: number;
  lunch: number;
  drinks: number;
  highTea: number;
  highWineBeer: number;
  other: number;
  reservations: Reservation[];
}

export default defineComponent({
  components: {
    PrognosisComponent,
    ReservationComponent,
    WeatherComponent,
  },
  props: {
    date: {
      required: true,
      type: Date,
    },
    establishment: {
      required: true,
      type: Object as PropType<Establishment>,
    },
    dailyBalanceData: {
      required: true,
      type: Array as PropType<DailyBalanceDto[]>,
    },
  },
  setup(props) {
    const loading = ref(false);
    const error = ref('');
    const weekOverview = ref<DayOverviewObject[]>([]);
    const weatherForecast = ref<CurrentForecastWeatherData | undefined>();
    const weatherHistorical = ref<CachedWeatherData[]>([]);
    const shifts = ref<Shift[]>([]);
    const wageCosts = ref<WageCosts[]>([]);
    const allReservations = ref<Reservation[]>([]);
    const allPrognosis = ref<Prognosis[]>([]);

    const dateStartOfWeek = computed(() => {
      return startOfWeek(props.date, { weekStartsOn: 1 });
    });

    const startOfWeekStr = computed(() => {
      return format(dateStartOfWeek.value, 'yyyy-MM-dd');
    });

    const dateStartOfNextWeek = computed(() => {
      return startOfWeek(addWeeks(dateStartOfWeek.value, 1), { weekStartsOn: 1 });
    });

    const startOfNextWeekStr = computed(() => {
      return format(dateStartOfNextWeek.value, 'yyyy-MM-dd');
    });

    function getShifts(date: Date) {
      return shifts.value.filter(s => isSameDay(date, s.date));
    }

    function getWageCosts(date: Date) {
      return (
        wageCosts.value.find(wc => isSameDay(date, wc.dob)) ?? {
          dob: date,
          plannedHours: 0,
          plannedCosts: 0,
        }
      );
    }

    function getDailyBalance(date: Date) {
      const dob = format(date, 'yyyy-MM-dd');
      return props.dailyBalanceData.filter(d => d.dob === dob)?.[0];
    }

    function getPrognosis(date: Date) {
      if (date !== undefined) {
        const selectedPrognosis: Prognosis[] = [];
        const dateFormatted = format(new Date(date), 'yyyy-MM-dd');

        allPrognosis.value.forEach(prognosis => {
          const prognosisDate = format(new Date(prognosis.date), 'yyyy-MM-dd');
          if (prognosisDate === dateFormatted) {
            selectedPrognosis.push(prognosis);
          }
        });
        return selectedPrognosis;
      } else {
        return undefined;
      }
    }

    function formatWeatherIcon(iconId: number) {
      if (iconId === null) {
        return '';
      }
      return 'owm-' + iconId;
    }

    function getWeather(date: Date) {
      const dateFormatted = format(date, 'yyyy-MM-dd');
      const today = format(new Date(), 'yyyy-MM-dd');

      let weatherData: WeatherData = {
        tempDay: 'nb',
        tempEve: 'nb',
        weatherIcon: 'nb',
        wind: 0,
        windIcon: 0,
        precipitation: '0',
      };

      if (dateFormatted < today) {
        weatherHistorical.value.forEach(data => {
          if ('hourly' in data.data) {
            const afternoonData = data.data.hourly[12];
            const eveningData = data.data.hourly[20];
            let precipitation = 0;
            if (afternoonData?.rain !== undefined) {
              precipitation = afternoonData?.rain['1h'];
            } else if (afternoonData?.snow !== undefined) {
              precipitation = afternoonData?.snow['1h'];
            }
            const weatherDate = format(new Date(data.date), 'yyyy-MM-dd');
            if (weatherDate === dateFormatted) {
              weatherData = {
                tempDay: afternoonData?.temp?.toFixed(0),
                tempEve: eveningData?.temp?.toFixed(0),
                weatherIcon: formatWeatherIcon(afternoonData?.weather?.[0]?.id),
                wind: eveningData?.wind_speed,
                windIcon: eveningData?.wind_deg,
                precipitation: precipitation.toFixed(0),
              };
            }
          }
          if ('daily' in data.data && 'aggregate' in data.data) {
            const dailyData = data.data.daily;
            const aggData = data.data.aggregate;
            const weatherDate = format(new Date(data.date), 'yyyy-MM-dd');
            if (weatherDate === dateFormatted) {
              weatherData = {
                tempDay: aggData.temperature.afternoon.toFixed(0),
                tempEve: aggData.temperature.evening.toFixed(0),
                weatherIcon: formatWeatherIcon(dailyData.weather?.[0]?.id),
                wind: aggData.wind.max.speed,
                windIcon: aggData.wind.max.direction,
                precipitation: aggData.precipitation.total.toFixed(0),
              };
            }
          }
        });
      } else {
        weatherForecast.value?.daily.forEach(data => {
          let precipitation = 0;
          const weatherDate = format(new Date(data.dt * 1000), 'yyyy-MM-dd');
          if (data.rain !== undefined) {
            precipitation = data.rain;
          } else if (data.snow !== undefined) {
            precipitation = data.snow;
          }
          if (weatherDate === dateFormatted) {
            weatherData = {
              tempDay: data.temp.day.toFixed(0),
              tempEve: data.temp.eve.toFixed(0),
              weatherIcon: formatWeatherIcon(data.weather[0].id),
              wind: data.wind_speed,
              windIcon: data.wind_deg,
              precipitation: precipitation.toFixed(0),
            };
          }
        });
      }

      return weatherData;
    }

    function getReservations(date: Date) {
      const searchDate = format(date, 'yyyy-MM-dd');
      const reservations: ReservationData = {
        dinner: 0,
        lunch: 0,
        drinks: 0,
        highTea: 0,
        highWineBeer: 0,
        other: 0,
        reservations: [],
      };

      allReservations.value.forEach(reservation => {
        const reservationDate = format(new Date(reservation.date), 'yyyy-MM-dd');
        if (searchDate === reservationDate) {
          reservations.reservations.push(reservation);
          switch (reservation.type) {
            case ReservationType.DINNER:
              reservations.dinner += reservation.amountOfPeople;
              break;
            case ReservationType.LUNCH:
              reservations.lunch += reservation.amountOfPeople;
              break;
            case ReservationType.DRINKS:
              reservations.drinks += reservation.amountOfPeople;
              break;
            case ReservationType.HIGH_TEA:
              reservations.highTea += reservation.amountOfPeople;
              break;
            case ReservationType.HIGH_WINE_BEER:
              reservations.highWineBeer += reservation.amountOfPeople;
              break;
            case ReservationType.OTHER:
              reservations.other += reservation.amountOfPeople;
              break;
          }
        }
      });
      return reservations;
    }

    async function loadData() {
      let date = dateStartOfWeek.value;
      const endDate = dateStartOfNextWeek.value;

      while (date < endDate) {
        const dayOverviewObject: DayOverviewObject = {
          date: date,
          dayName: format(date, 'iii', { locale }),
        };
        weekOverview.value.push(dayOverviewObject);
        date = addDays(date, 1);
      }

      for (let i = 0; i < weekOverview.value.length; i++) {
        const date = weekOverview.value[i].date;
        weekOverview.value[i].weather = getWeather(date);
        weekOverview.value[i].reservations = getReservations(date);
        weekOverview.value[i].prognosis = getPrognosis(date);
        weekOverview.value[i].dailyBalance = getDailyBalance(date);
        weekOverview.value[i].shifts = getShifts(date);
        weekOverview.value[i].wageCosts = getWageCosts(date);
      }
    }

    async function getWeatherData() {
      try {
        weatherForecast.value = await getCurrentWeather(props.establishment.id);
        weatherHistorical.value = await getHistoricalWeather(
          props.establishment.id,
          dateStartOfWeek.value,
          dateStartOfNextWeek.value,
        );
      } catch (e) {
        error.value = e;
      }
    }

    async function getReservationsData() {
      try {
        allReservations.value = await reservationsService.getReservations(
          props.establishment.id,
          startOfWeekStr.value,
          startOfNextWeekStr.value,
        );
        allReservations.value.sort((a, b) => (a.date > b.date ? 1 : -1));
      } catch (e) {
        error.value = e;
      }
    }

    async function getPrognosisData() {
      try {
        allPrognosis.value = await GetPrognosisByDateRange(
          props.establishment.id,
          startOfWeekStr.value,
          startOfNextWeekStr.value,
        );
      } catch (e) {
        error.value = e;
      }
    }

    async function getShiftData() {
      try {
        shifts.value = await searchShifts({
          establishmentId: props.establishment.id,
          from: startOfWeekStr.value,
          to: startOfNextWeekStr.value,
        });
      } catch (e) {
        error.value = e;
      }
    }

    async function getWageCostsData() {
      try {
        wageCosts.value = await searchWageCosts({
          establishmentId: props.establishment.id,
          from: startOfWeekStr.value,
          to: startOfNextWeekStr.value,
        });
      } catch (e) {
        error.value = e;
      }
    }

    onMounted(async () => {
      try {
        loading.value = true;
        await Promise.all([
          getWeatherData(),
          getReservationsData(),
          getPrognosisData(),
          getShiftData(),
          getWageCostsData(),
        ]);
        await loadData();
      } catch (e) {
        error.value = e;
      } finally {
        loading.value = false;
      }
    });

    function getIcon(type: string) {
      if (type === ReservationType.DINNER) {
        return 'utensils';
      }
      if (type === ReservationType.LUNCH) {
        return 'utensils';
      }
      if (type === ReservationType.DRINKS) {
        return 'glass-cheers';
      }
      if (type === ReservationType.HIGH_WINE_BEER) {
        return 'beer';
      }
      if (type === ReservationType.HIGH_TEA) {
        return 'coffee';
      }
      if (type === ReservationType.OTHER) {
        return 'question';
      }
    }

    function formatTime(rawTime: string | null) {
      if (!rawTime) return;
      let timeNumber = parseInt(rawTime.slice(0, 2), 10);
      if (timeNumber > 23) {
        timeNumber = timeNumber - 24;
        rawTime = timeNumber < 10 ? '0' + timeNumber.toString() : timeNumber.toString();
        rawTime = rawTime + ':00:00';
      }
      return rawTime.substring(0, 5);
    }

    function formatDate(date: Date) {
      return format(date, 'dd-MM-yyyy');
    }

    function isToday(date: Date) {
      const today = format(new Date(), 'yyyy-MM-dd');
      const checkDate = format(new Date(date), 'yyyy-MM-dd');
      return today === checkDate;
    }

    return {
      loading,
      error,
      weekOverview,
      weatherForecast,
      weatherHistorical,
      shifts,
      wageCosts,
      allReservations,
      allPrognosis,
      getIcon,
      formatTime,
      formatDate,
      isToday,
    };
  },
});
