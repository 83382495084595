import InsightsView from './InsightsView.vue';
import BalanceInsights from './components/BalanceInsights.vue';

export default [
  {
    path: '/insights/:establishmentId',
    name: 'insights',
    component: InsightsView,
    redirect: { name: 'BalanceInsights' },
    children: [
      {
        path: 'kasverschil',
        name: 'BalanceInsights',
        component: BalanceInsights,
      },
    ],
  },
];
