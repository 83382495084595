import client from '../client';
import useAxios from '@/util/axios';
import { normalize } from './serialize';
import { ShiftReviewDto, ShiftReviewEditFormDto } from './types';

const { getCancelToken } = useAxios();

export async function findByJournal(journalId: number, signal?: AbortSignal) {
  const result = await client.get<ShiftReviewDto[]>(`/journals/${journalId}/shift-reviews`, {
    cancelToken: getCancelToken(signal),
  });
  return result.data.map(normalize);
}

export async function update(id: number, dto: Partial<ShiftReviewEditFormDto>) {
  const response = await client.put<ShiftReviewDto>(`/shift-reviews/${id}`, dto);
  return normalize(response.data);
}
