
import { defineComponent, PropType } from '@vue/composition-api';
import { Department, Establishment } from '@/common';
import PlanningLimitTable from './PlanningLimitTable.vue';
import InventoryItemsEditForm from '../../general/InventoryItemsEditForm.vue';

export default defineComponent({
  components: {
    PlanningLimitTable,
    InventoryItemsEditForm,
  },
  props: {
    establishment: {
      type: Object as PropType<Establishment>,
      required: true,
    },
    daypartId: {
      type: Number,
      required: true,
    },
    department: {
      type: Object as PropType<Department>,
      required: true,
    },
  },
  setup(props, { emit }) {
    function submit() {
      emit('submit');
    }

    return {
      props,
      submit,
    };
  },
});
