import axios from 'axios';
import { Auth } from '@/service/auth';

const client = axios.create({
  baseURL: process.env.VUE_APP_JOURNAL_API_URL,
  timeout: process.env.VUE_APP_API_TIMEOUT,
  headers: { Accept: 'application/json' },
});

client.interceptors.request.use(async config => {
  const token = await Auth.getToken();
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };
  return config;
});

client.interceptors.response.use(async response => {
  return response;
});

export default client;
