import client from '../client';
import { DailyBalanceDto, DailyBalanceMiscData, DailyBalanceUserData } from '@/types/dailyBalance';

export async function listBetween({
  establishmentId,
  from,
  to,
}: {
  establishmentId: number;
  from: string;
  to: string;
}): Promise<DailyBalanceDto[]> {
  const response = await client.get<DailyBalanceDto[]>(
    `/establishments/${establishmentId}/daily-balance`,
    { params: { from, to } },
  );
  return response.data;
}

export async function updateUserData(
  establishment: number,
  dob: string,
  data: DailyBalanceUserData,
) {
  const response = await client.put<DailyBalanceDto>(
    `/establishments/${establishment}/daily-balance/${dob}/user-data`,
    data,
  );
  return response.data;
}

export async function updateMiscData(
  establishment: number,
  dob: string,
  data: DailyBalanceMiscData,
) {
  const response = await client.put<DailyBalanceDto>(
    `/establishments/${establishment}/daily-balance/${dob}/misc-data`,
    data,
  );
  return response.data;
}
