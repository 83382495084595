
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { addMonths, format, startOfMonth } from 'date-fns';
import { useRouter } from '@u3u/vue-hooks';
import { listBetween } from '@/api/dailyBalance';
import { DailyBalancePropertyTypes } from '@/types/dailyBalance';

const currFmt = Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

const COLUMNS = {
  turnoverInclVat: 'Omzet (incl)',
  turnoverExclVat: 'Omzet (excl)',
  crCash: 'Contant (kassa)',
  crPin: 'Pin (kassa)',
  crIZettle: 'iZettle (kassa)',
  cash: 'Contant',
  pin: 'Pin',
  iZettle: 'iZettle',
  voided: 'Void',
  debtor: 'Op rekening',
  vouchers: 'Bonnen',
  employeePayments: 'Bonnen/Contante betalingen',
  specialPayments: 'Speciale betalingen',
  offered: 'Aangeboden',
  representation: 'Representatie',
  totalRegisterAmount: 'Totaal in kas',
  differenceAmount: 'Kasverschil',
} as const;

type ColumnKey = keyof typeof COLUMNS;

type Row = {
  [k in ColumnKey]: number;
} & { label: string };

export default defineComponent({
  setup() {
    const { route } = useRouter();

    const today = new Date();
    const establishmentId = computed(() => Number(route.value.params.establishmentId));
    const from = ref(format(startOfMonth(today), 'yyyy-MM-dd'));
    const to = ref(format(startOfMonth(addMonths(today, 1)), 'yyyy-MM-dd'));
    const columns = ref<ColumnKey[]>([]);
    const columnOptions = computed(() => {
      return Object.keys(COLUMNS)
        .filter(key => !columns.value.includes(key as ColumnKey))
        .map(key => ({ value: key, text: COLUMNS[key as ColumnKey] }))
        .sort((a, b) => a.text.localeCompare(b.text));
    });

    if (localStorage.insightsBalanceColumns) {
      try {
        columns.value = JSON.parse(localStorage.insightsBalanceColumns);
      } catch (e) {
        localStorage.insightsBalanceColumns = JSON.stringify([]);
      }
    }

    const rows = ref<Row[]>([]);
    const isLoading = ref(false);
    const error = ref('');

    watch(columns, columns => {
      localStorage.insightsBalanceColumns = JSON.stringify(columns);
    });

    async function loadData() {
      try {
        isLoading.value = true;
        error.value = '';

        const data = await listBetween({
          establishmentId: establishmentId.value,
          from: from.value,
          to: to.value,
        });
        const totalTable = new Map<number, number>();
        rows.value = data.map(item => {
          const table = new Map<number, number>();
          item.properties.forEach(prop => {
            table.set(prop.type, prop.amount);
            totalTable.set(prop.type, (totalTable.get(prop.type) ?? 0) + prop.amount);
          });
          return {
            label: format(new Date(item.dob), 'dd-MM-yyyy'),
            turnoverInclVat: table.get(DailyBalancePropertyTypes.TURNOVER) ?? 0,
            turnoverExclVat: table.get(DailyBalancePropertyTypes.TURNOVER_EXCL_VAT) ?? 0,
            crCash: table.get(DailyBalancePropertyTypes.CR_CASH) ?? 0,
            crPin: table.get(DailyBalancePropertyTypes.CR_PIN) ?? 0,
            crIZettle: table.get(DailyBalancePropertyTypes.CR_IZETTLE) ?? 0,
            cash: table.get(DailyBalancePropertyTypes.CASH) ?? 0,
            pin: table.get(DailyBalancePropertyTypes.PIN) ?? 0,
            iZettle: table.get(DailyBalancePropertyTypes.IZETTLE) ?? 0,
            voided: table.get(DailyBalancePropertyTypes.VOIDED) ?? 0,
            debtor: table.get(DailyBalancePropertyTypes.DEBTOR) ?? 0,
            vouchers: table.get(DailyBalancePropertyTypes.VOUCHERS) ?? 0,
            employeePayments: table.get(DailyBalancePropertyTypes.EMPLOYEE_PAYMENTS) ?? 0,
            specialPayments: table.get(DailyBalancePropertyTypes.SPECIAL_PAYMENTS) ?? 0,
            offered: table.get(DailyBalancePropertyTypes.OFFERED) ?? 0,
            representation: table.get(DailyBalancePropertyTypes.REPRESENTATION) ?? 0,
            totalRegisterAmount: table.get(DailyBalancePropertyTypes.TOTAL_REGISTER_AMOUNT) ?? 0,
            differenceAmount: table.get(DailyBalancePropertyTypes.DIFFERENCE_AMOUNT) ?? 0,
          };
        });
        rows.value.push({
          label: 'Totaal',
          turnoverInclVat: totalTable.get(DailyBalancePropertyTypes.TURNOVER) ?? 0,
          turnoverExclVat: totalTable.get(DailyBalancePropertyTypes.TURNOVER_EXCL_VAT) ?? 0,
          crCash: totalTable.get(DailyBalancePropertyTypes.CR_CASH) ?? 0,
          crPin: totalTable.get(DailyBalancePropertyTypes.CR_PIN) ?? 0,
          crIZettle: totalTable.get(DailyBalancePropertyTypes.CR_IZETTLE) ?? 0,
          cash: totalTable.get(DailyBalancePropertyTypes.CASH) ?? 0,
          pin: totalTable.get(DailyBalancePropertyTypes.PIN) ?? 0,
          iZettle: totalTable.get(DailyBalancePropertyTypes.IZETTLE) ?? 0,
          voided: totalTable.get(DailyBalancePropertyTypes.VOIDED) ?? 0,
          debtor: totalTable.get(DailyBalancePropertyTypes.DEBTOR) ?? 0,
          vouchers: totalTable.get(DailyBalancePropertyTypes.VOUCHERS) ?? 0,
          employeePayments: totalTable.get(DailyBalancePropertyTypes.EMPLOYEE_PAYMENTS) ?? 0,
          specialPayments: totalTable.get(DailyBalancePropertyTypes.SPECIAL_PAYMENTS) ?? 0,
          offered: totalTable.get(DailyBalancePropertyTypes.OFFERED) ?? 0,
          representation: totalTable.get(DailyBalancePropertyTypes.REPRESENTATION) ?? 0,
          totalRegisterAmount: totalTable.get(DailyBalancePropertyTypes.TOTAL_REGISTER_AMOUNT) ?? 0,
          differenceAmount: totalTable.get(DailyBalancePropertyTypes.DIFFERENCE_AMOUNT) ?? 0,
        });
        rows.value.forEach(row => {
          row.differenceAmount *= -1;
        });
      } catch (e) {
        error.value = 'Er is iets misgegaan bij het ophalen van de gegevens';
      } finally {
        isLoading.value = false;
      }
    }

    watch(
      establishmentId,
      () => {
        loadData();
      },
      { immediate: true },
    );

    const formatCurrency = (val: number) => {
      return currFmt.format(val);
    };

    return {
      isLoading,
      error,
      COLUMNS,
      from,
      to,
      columns,
      columnOptions,
      loadData,
      rows,
      formatCurrency,
    };
  },
});
